import React from 'react'
import { navigate } from 'gatsby-link'

import NoImage from '../../../images/no-preview.svg'
import Buttons from '../../form/buttons'
import ListView from '../../mobile/list-view-transactions'

import { ConvertISODate1, toUpperCaseFirst } from '../../../utils/string'

import {
  LogoImage,
  MobileBoxSummary,
  MobileHeadTitle,
  MobileMoneyBox,
  MobileMoneyTitle,
  MobileWrapper,
  PcBoxSummary,
  PcBoxWrapperSearch,
  PcMenuTabs,
  PCWrapper,
  SearchForm,
  styleMobileButtonUpgrade,
  SummaryBoxItems,
  UpgradeBox,
} from './share'

const dropdownItems = locale => {
  return [
    { id: 'id', title: locale.TRANSACTION_ID },
    { id: 'receiver_name', title: locale.NAME },
    { id: 'receiver_email', title: locale.EMAIL_ADDRESS },
    { id: 'payment_method', title: locale.CHANNEL },
    { id: 'amount', title: locale.AMOUNT },
    { id: 'created_at', title: locale.CREATED_DATE },
    { id: 'status', title: locale.STATUS },
  ]
}

const dynamicSearch = {
  status: [
    { id: 'failed', title: 'Failed' },
    { id: 'expired', title: 'Expired' },
    { id: 'pending', title: 'Pending' },
    { id: 'reversed', title: 'Reversed' },
    { id: 'successful', title: 'Successful' },
  ],
  payment_method: [
    { id: 'credit_card', title: 'Credit Card' },
    { id: 'internet_banking', title: 'Internet Banking' },
  ],
  created_at: true,
}

const onDetail = (fn, payload) => fn.bind(null, payload)
const onGoEdit = id => () => navigate(`/event-detail/edit/?id=${id}`)
const style1 = { fontWeight: 'bold' }

function PcView(props) {
  return (
    <React.Fragment>
      <PCWrapper>
        <div>
          <LogoImage
            src={props.logo}
            alt=""
            onClick={onGoEdit(props.eventID)}
          />
        </div>
        <div style={style1}>{props.name}</div>
        <div>
          <PcBoxSummary>
            <SummaryBoxItems>
              <h5>{props.packagesName}</h5>
              <small>{props.locale.PACKAGE}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>
                {props.sumInvitations} / {props.maxInvitations || 0}
              </h5>
              <small>{props.locale.INVITATIONS}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>{props.sumJoined}</h5>
              <small>{props.locale.JOINED}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>{props.sumMoney}</h5>
              <small>{props.currency}</small>
            </SummaryBoxItems>
          </PcBoxSummary>
        </div>
        <UpgradeBox>
          <Buttons.Button5
            onClick={props.onUpgrade}
            style={styleMobileButtonUpgrade}
          >
            {props.locale.UPGRADE}
          </Buttons.Button5>
        </UpgradeBox>
        <PcMenuTabs
          name="TRANSACTIONS"
          onClick={props.onClickTab}
          locale={props.locale}
        />
        <PcBoxWrapperSearch>
          <div>
            <SearchForm
              data={dropdownItems(props.locale)}
              dynamicSearch={dynamicSearch}
              keyword={props.keyword}
              keywordType={props.keywordType}
              onKeyword={props.onKeyword}
              onKeywordType={props.onKeywordType}
              onSearch={props.onSearch}
            />
          </div>
        </PcBoxWrapperSearch>
        <div>
          <table className="transaction-table">
            <thead>
              <tr>
                <th>{props.locale.TRANSACTION_ID}</th>
                <th>{props.locale.NAME}</th>
                <th>{props.locale.EMAIL_ADDRESS}</th>
                <th>{props.locale.CHANNEL}</th>
                <th>
                  {props.locale.AMOUNT} ({props.locale.THB})
                </th>
                <th>{props.locale.CREATED_DATE}</th>
                <th>{props.locale.STATUS}</th>
              </tr>
            </thead>
            <tbody>
              {props.data.length > 0 ? (
                props.data.map((v, i) => {
                  return (
                    <tr key={`transaction-table-${i}`}>
                      <td>{v.id}</td>
                      <td>{v.invitation_receiver_name || '-'}</td>
                      <td>
                        <span
                          className="span-link"
                          onClick={onDetail(props.onDetail, v)}
                        >
                          {v.invitation_receiver_email || '-'}
                        </span>
                      </td>
                      <td>
                        {v.payment_method === 'internet_banking'
                          ? 'Internet Banking'
                          : v.payment_method === 'credit_card'
                          ? 'Credit Card'
                          : v.payment_method}
                      </td>
                      <td>{v.amount}</td>
                      <td>{ConvertISODate1(v.created_at)}</td>
                      <td>{toUpperCaseFirst(v.status)}</td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>-- {props.locale.NO_DATA} --</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PCWrapper>
    </React.Fragment>
  )
}

function MobileView(props) {
  return (
    <MobileWrapper>
      <MobileHeadTitle>{props.name}</MobileHeadTitle>
      <MobileMoneyBox>
        <MobileMoneyTitle>
          {props.sumMoney} {props.currency}
        </MobileMoneyTitle>
      </MobileMoneyBox>
      <MobileBoxSummary>
        <SummaryBoxItems>
          <h5>{props.packagesName || '-'}</h5>
          <small>{props.locale.PACKAGE}</small>
        </SummaryBoxItems>
        <SummaryBoxItems>
          <h5>
            {props.sumInvitations || 0} / {props.maxInvitations || 0}
          </h5>
          <small>{props.locale.INVITATIONS}</small>
        </SummaryBoxItems>
        <SummaryBoxItems>
          <h5>{props.sumJoined || 0}</h5>
          <small>{props.locale.JOINED}</small>
        </SummaryBoxItems>
      </MobileBoxSummary>
      <UpgradeBox>
        <Buttons.Button5
          onClick={props.onUpgrade}
          style={styleMobileButtonUpgrade}
        >
          {props.locale.UPGRADE}
        </Buttons.Button5>
      </UpgradeBox>
      <PcMenuTabs name="TRANSACTIONS" onClick={props.onClickTab} isMobile />
      <ListView
        data={props.data}
        onClick={payload => {
          props.onDetail(payload)
        }}
      />
    </MobileWrapper>
  )
}

PcView.defaultProps = {
  eventID: 0,
  logo: NoImage,
  name: '',
  sumMoney: '0',
  currency: 'THB',
  packagesName: 'Free',
  sumInvitations: 0,
  maxInvitations: 0,
  sumJoined: 0,
  data: [],
  keyword: '',
  keywordType: '',
  onUpgrade: null,
  onNew: null,
  onSearch: null,
  onNewInvitation: null,
  onKeyword: null,
  onKeywordType: null,
  onClickTab: null,
  onDetail: null,
  locale: null,
}

MobileView.defaultProps = {
  eventID: 0,
  name: '',
  sumMoney: '0',
  currency: 'THB',
  packagesName: 'Free',
  sumInvitations: 0,
  maxInvitations: 0,
  sumJoined: 0,
  data: [],
  keyword: '',
  keywordType: '',
  onUpgrade: null,
  onNew: null,
  onSearch: null,
  onNewInvitation: null,
  onKeyword: null,
  onKeywordType: null,
  onClickTab: null,
  onDetail: null,
  locale: null,
}

export default props => (
  <div className="transaction-page">
    <MobileView
      eventID={props.eventID}
      name={props.name}
      packagesName={props.packagesName}
      sumInvitations={props.sumInvitations}
      maxInvitations={props.maxInvitations}
      sumJoined={props.sumJoined}
      sumMoney={props.sumMoney}
      currency={props.currency}
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onUpgrade={props.onUpgrade}
      onSearch={props.onSearch}
      onNewInvitation={props.onNewInvitation}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onClickTab={props.onClickTab}
      onDetail={props.onDetail}
      locale={props.locale}
    />
    <PcView
      eventID={props.eventID}
      logo={props.logo}
      name={props.name}
      packagesName={props.packagesName}
      sumInvitations={props.sumInvitations}
      maxInvitations={props.maxInvitations}
      sumJoined={props.sumJoined}
      sumMoney={props.sumMoney}
      currency={props.currency}
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onUpgrade={props.onUpgrade}
      onSearch={props.onSearch}
      onNewInvitation={props.onNewInvitation}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onClickTab={props.onClickTab}
      onDetail={props.onDetail}
      locale={props.locale}
    />
  </div>
)
