import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import queryString from 'query-string'
import moment from 'moment'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'
import transactionActions from '../state/transaction/actions'

import View from '../components/presentation/manage-event/transaction'
import ModalDetail from '../components/popup/transaction-detail'
import UpgradePackage from '../components/box-create-events/box-create-events'

// import JsonTree from '../components/json-tree'

function handle(props) {
  const params = queryString.parse(props.location.search)

  const [stateUI, setStateUI] = useState('MAIN')
  const [isLoading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [keywordType, setKeywordType] = useState('id')

  useEffect(function() {
    props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        props.onLoad({
          id: params.id,
          search: {
            keyword,
            keywordType,
          },
          callback: () => {
            setLoading(false)
          },
        })
      },
    })
  }, [])

  function onSearch() {
    setLoading(true)
    props.onLoad({
      id: params.id,
      search: {
        keyword,
        keywordType,
      },
      callback: () => {
        setLoading(false)
      },
    })
  }

  function onNewInvitation() {
    console.log('onSearch')
  }

  function onKeyword(e) {
    if (e.currentTarget) {
      setKeyword(e.currentTarget.value)
    } else {
      setKeyword(e)
    }
  }

  function onKeywordType(e) {
    if (e.currentTarget.value === 'created_at') {
      const d = new Date()
      const form = moment(d).format('YYYY-MM-DDT00:00:00')
      const to = moment(d).format('YYYY-MM-DDT23:59:59')
      setKeyword({ form: form + '.00Z', to: to + '.00Z' })
    } else {
      setKeyword('')
    }
    setKeywordType(e.currentTarget.value)
  }

  function onClickTab(type) {
    if (type === 1) {
      navigate('/invitations/?id=' + params.id)
    } else if (type === 2) {
      navigate('/transactions/?id=' + params.id)
    } else if (type === 3) {
      navigate('/ticket-goods/?id=' + params.id)
    }
  }

  let totalSummary = {
    event: {
      logo: '',
      title_en: '',
      title_th: '',
    },
    package: {
      name_en: '',
      name_th: '',
      currency: 'thb',
    },
    invitation_count: 0,
    joined_count: 0,
    max_invitations: 0,
    total_amount: '0',
  }

  if (props.state.totalSummary) {
    if (props.state.totalSummary.event) {
      totalSummary = props.state.totalSummary
    }
  }

  return {
    stateUI,
    isLoading,
    eventID: params.id,
    logo: totalSummary.event.logo
      ? totalSummary.event.logo + '?sz=m'
      : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO + '?sz=m',
    name:
      (props.app.lang === 'TH'
        ? totalSummary.event.title_th
        : totalSummary.event.title_en) || '',
    // packageID: totalSummary.package.id || 0,
    packagesName:
      (props.app.lang === 'TH'
        ? totalSummary.package.name_th
        : totalSummary.package.name_en) || '',
    sumInvitations: totalSummary.invitation_count,
    maxInvitations: totalSummary.max_invitations,
    sumJoined: totalSummary.joined_count,
    sumMoney: totalSummary.total_amount,
    currency: totalSummary.package.currency.toUpperCase(),
    dataList: props.state.transactionList.transactions,
    keyword,
    keywordType, // [Name,Email,Date,Status]
    onSearch,
    onNewInvitation,
    onKeyword,
    onKeywordType,
    onClickTab,
    setStateUI,
  }
}

function TransactionPage(props) {
  const {
    stateUI,
    isLoading,
    eventID,
    logo,
    name,
    // packageID,
    packagesName,
    sumInvitations,
    maxInvitations,
    sumJoined,
    sumMoney,
    currency,
    dataList,
    keyword,
    keywordType, // [Name,Email,Date,Status]
    onSearch,
    onNewInvitation,
    onKeyword,
    onKeywordType,
    onClickTab,
    setStateUI,
  } = handle(props)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Layout auth={props.auth.currentUser !== null}>
      <SEO keywords={[`invitree`]} />
      {stateUI === 'UPGRADE' ? (
        <div className="__header-box" id="owl-bg-red">
          <UpgradePackage
            title={props.app.locale[props.app.lang].UPGRADE_PACKAGE}
            textChoose={props.app.locale[props.app.lang].CHOOSE}
            data={props.app.packageForUpgrade || []}
            onChoose={packID => {
              props.onChooseEvent(packID, 'upgrade_package', eventID)
            }}
            locale={props.app.locale[props.app.lang]}
          />
        </div>
      ) : (
        <View
          eventID={eventID}
          logo={logo}
          name={name}
          packagesName={packagesName}
          sumInvitations={sumInvitations}
          maxInvitations={maxInvitations}
          sumJoined={sumJoined}
          sumMoney={sumMoney}
          currency={currency}
          data={dataList}
          onUpgrade={() => setStateUI('UPGRADE')}
          keyword={keyword}
          keywordType={keywordType}
          onSearch={onSearch}
          onNewInvitation={onNewInvitation}
          onKeyword={onKeyword}
          onKeywordType={onKeywordType}
          onClickTab={onClickTab}
          onDetail={props.onDetail}
          locale={props.app.locale[props.app.lang]}
        />
      )}
      <ModalDetail
        data={props.state.transactionDetail}
        locale={props.app.locale[props.app.lang]}
      />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.transaction,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: transactionActions.listTransaction,
      onDetail: transactionActions.onShowTransactionDetail,
      onChooseEvent: homeActions.onChooseEvent,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionPage)
